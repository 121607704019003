import { PACKAGE_BOUGHT } from './packageStatus'

export const API_URL = process.env.REACT_APP_API_URL

export const CMS_ADAPTER_URL = process.env.REACT_APP_CMS_ADAPTER_URL

export const PINFLAG_BOT_URL = process.env.REACT_APP_PINFLAG_BOT_URL

export const MONDAY_API_URL = process.env.REACT_APP_MONDAY_API_URL

export const MONDAY_API_KEY = process.env.REACT_APP_MONDAY_API_KEY

export const RETURN_REQUEST_BOARD_ID = process.env.REACT_APP_BOARD_ID_RETURN_REQUEST

export const POINT_STATS = '/warehouses/stats'

export const PACKAGES_BY_STATUS = (status) => `/warehouse/packages?status=${status}`

export const PACKAGES_FOR_NOTIFICATIONS = () =>
  `/warehouse/packages?serviceType=ship-from-store,pick-from-store&status=${PACKAGE_BOUGHT}`

export const PACKAGES_BY_SERVICE_TYPE_AND_STATUS = ({ serviceType, status }) => {
  const params = new URLSearchParams()
  params.append('packageType', serviceType === 'distribution' ? 'distribution' : 'ecommerce')
  if (serviceType) params.append('serviceType', serviceType)
  if (status) params.append('status', status)
  return `/warehouse/packages${params.toString() ? `?${params.toString()}` : ''}`
}

export const INGRESS_PACKAGE = (packageId) =>
  `/warehouse/inputs/companies/packages/${packageId}/reception/confirm`

export const SALE_URL = (packageId) => `/warehouse/package/${packageId}`

export const STORE_PACKAGE = '/warehouse/store-packages'

export const PICK_PACKAGE = (packageId) =>
  `/warehouse/outputs/clients/packages/${packageId}/generate/code`

export const DELIVER_PACKAGE = (packageId) =>
  `warehouse/outputs/clients/packages/${packageId}/evidence`

export const PACKAGE_PERSONALIZATION = (packageId) =>
  `warehouse/${packageId}/personalization`

export const CHATBOT_INFO = '/warehouse/chatbot-info'

export const DISTRIBUTION_RETURN_URL = '/packages/create-replenishment'

export const MANIFEST_CREATE_URL = '/manifest/create'

export const GET_SKU = '/product/variant'

export const GET_MANIFEST = '/manifest'

export const GET_MANIFEST_BY_ID = (manifestId) => `/manifest/${manifestId}`
