import { useState, useEffect, useCallback } from 'react'
import { fetchColumnsData, sendFormData } from '../../../helpers/requests/returnRequestForm'
import { RETURN_REQUEST_BOARD_ID } from '../../../constants/api'
import useFetch from '../../../hooks/useFetch'
import { getPointStats } from '../../../helpers/requests/pointInformation'

export const useReturnRequest = () => {
  const [columnIds, setColumnIds] = useState({})
  const [loadingColumns, setLoadingColumns] = useState(true)
  const [submitting, setSubmitting] = useState(false)
  const [pointInfo, setPointInfo] = useState({})
  const [formData, setFormData] = useState({
    applicantName: '',
    applicantPhone: '',
    storeEmail: '',
    packageCount: '',
    totalWeight: '',
    date: '',
    timeFrom: '',
    timeTo: '',
    comments: ''
  })
  const [message, setMessage] = useState(null)

  useFetch(getPointStats, setPointInfo)

  const mapColumns = (columns) => {
    const mappedColumns = {}
    columns.forEach((col) => {
      if (col.title === 'Warehouse ID') mappedColumns.warehouseId = col.id
      if (col.title === 'Point ID') mappedColumns.pointId = col.id
      if (col.title === 'Nombre de la Tienda') mappedColumns.storeName = col.id
      if (col.title === 'Dirección') mappedColumns.address = col.id
      if (col.title === 'Nombre del Solicitante') mappedColumns.applicantName = col.id
      if (col.title === 'Teléfono del Solicitante') mappedColumns.applicantPhone = col.id
      if (col.title === 'Email de la Tienda') mappedColumns.storeEmail = col.id
      if (col.title === 'Numero de Bultos') mappedColumns.packageCount = col.id
      if (col.title === 'Peso Total de los Bultos') mappedColumns.totalWeight = col.id
      if (col.title === 'Fecha de Retiro') mappedColumns.pickupDate = col.id
      if (col.title === 'Fecha de Solicitud') mappedColumns.requestDate = col.id
      if (col.title === 'Ventana Horaria') mappedColumns.timeWindow = col.id
      if (col.title === 'Comentarios adicionales') mappedColumns.comments = col.id
    })

    return mappedColumns
  }

  const fetchColumns = useCallback(async () => {
    try {
      const columns = await fetchColumnsData()
      const mappedColumns = mapColumns(columns)
      setColumnIds(mappedColumns)
      setLoadingColumns(false)
    } catch (e) {
      setLoadingColumns(false)
    }
  }, [])

  const getColumnsValues = (formValues, columnsMapping) => {
    const currentDate = new Date().toISOString().split('T')[0]
    return {
      [columnsMapping.warehouseId]: pointInfo.warehouseInfo?.warehouseId || '',
      [columnsMapping.pointId]: pointInfo.warehouseInfo?.courierPointId || '',
      [columnsMapping.storeName]: pointInfo.warehouseInfo?.nombrebodega || '',
      [columnsMapping.address]: pointInfo.warehouseInfo?.address || '',
      [columnsMapping.applicantName]: formValues.applicantName || '',
      [columnsMapping.applicantPhone]: formValues.applicantPhone || '',
      [columnsMapping.storeEmail]: formValues.storeEmail || '',
      [columnsMapping.packageCount]: parseInt(formValues.packageCount, 10) || null,
      [columnsMapping.totalWeight]: parseFloat(formValues.totalWeight) || null,
      [columnsMapping.pickupDate]: formValues.date ? { date: formValues.date } : null,
      [columnsMapping.requestDate]: { date: currentDate },
      [columnsMapping.timeWindow]: formValues.timeFrom && formValues.timeTo
        ? `${formValues.timeFrom} a ${formValues.timeTo}`
        : '',
      [columnsMapping.comments]: formValues.comments || ''
    }
  }

  const prepareColumnValues = (formValues, columnsMapping) => {
    const columnValues = getColumnsValues(formValues, columnsMapping)
    return Object.fromEntries(
      Object.entries(columnValues).filter(([, value]) => value !== undefined && value !== null)
    )
  }

  const validateAndPrepareForm = (formValues, columnsMapping) => {
    if (Object.keys(columnsMapping).length === 0) {
      return null
    }
    return prepareColumnValues(formValues, columnsMapping)
  }

  const buildMutationVariables = (formValues, colsValues) => {
    const variables = {
      boardId: RETURN_REQUEST_BOARD_ID,
      itemName: pointInfo.warehouseInfo?.nombrebodega,
      columnValues: JSON.stringify(colsValues)
    }

    return variables
  }

  const resetFormData = () => {
    setFormData({
      applicantName: '',
      applicantPhone: '',
      storeEmail: '',
      packageCount: '',
      totalWeight: '',
      date: '',
      timeFrom: '',
      timeTo: '',
      comments: ''
    })
  }

  const submitForm = async () => {
    setSubmitting(true)
    const columnValues = validateAndPrepareForm(formData, columnIds)
    if (!columnValues) {
      setMessage({ type: 'error', text: 'Error al preparar los datos del formulario' })
      setSubmitting(false)
      return
    }

    const variables = buildMutationVariables(formData, columnValues)

    try {
      await sendFormData(variables)
      resetFormData()
      setMessage({ type: 'success', text: 'Solicitud enviada con éxito' })
    } catch (e) {
      setMessage({ type: 'error', text: 'Error al enviar la solicitud' })
    }
    setSubmitting(false)
  }

  useEffect(() => {
    fetchColumns()
  }, [fetchColumns])

  const handleChange = (e) => {
    const { name, value } = e.target
    setFormData((prevData) => ({ ...prevData, [name]: value }))
  }

  return {
    columnIds,
    loadingColumns,
    submitting,
    formData,
    message,
    setMessage,
    handleChange,
    submitForm,
    pointInfo
  }
}

export default useReturnRequest
