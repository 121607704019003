import React from 'react'

import Loader from '../../shared/Loader'
import Header from '../../Header'

const ReturnRequestView = ({
  loadingColumns,
  submitting,
  formData,
  message,
  setMessage,
  handleChange,
  handleSubmit,
  pointInfo
}) => {
  if (loadingColumns) {
    return (
      <div className="flex h-screen w-full items-center justify-center bg-gray-50">
        <Loader />
      </div>
    )
  }

  return (
    <div className="w-full overflow-auto bg-gray-50">
      <Header to="/" name="Solicitud de Retiro" qrScannerStyle />
      <div className="mx-auto mt-10 px-4 py-8">
        <div className="mx-auto max-w-3xl">
          <div className="mb-6 rounded-t-lg bg-white p-6 shadow-sm">
            <p className="mt-2 text-gray-600">
              Complete el formulario para solicitar un retiro de mercancía
            </p>
          </div>

          {message ? (
            <div>
              <div
                className={`mb-6 rounded-md px-4 py-3 shadow-sm ${
                  message.type === 'success'
                    ? 'border-l-4 border-green/50 bg-green/10 text-green/80'
                    : 'border-l-4 border-red/50 bg-red/10 text-red/80'
                }`}
              >
                <div className="flex items-center">
                  <span className="mr-2 text-xl">{message.type === 'success' ? '✓' : '✕'}</span>
                  <p>{message.text}</p>
                </div>
              </div>
              <button
                type="button"
                onClick={() => setMessage(null)}
                className="focus:ring-2focus:ring-blue/100 w-full rounded-md bg-pinflag-dark px-4 py-3 text-base
                  font-medium text-white shadow-sm transition hover:bg-pinflag-dark/80
                  focus:outline-none"
              >
                Solicitar otro Retiro
              </button>
            </div>
          ) : (
            <form
              onSubmit={handleSubmit}
              className="divide-y divide-gray-200 overflow-hidden rounded-lg bg-white shadow-sm"
            >
              <div className="p-6">
                <h2 className="mb-4 text-lg font-semibold text-gray-800">
                  Información de la Tienda
                </h2>
                <div className="space-y-4">
                  <div>
                    <label className="mb-1 block text-sm font-medium text-gray-700">
                      Nombre de la Tienda
                    </label>
                    <div className="block w-full rounded-md border-2 border-gray-300 bg-gray-50 px-4 py-3 shadow-sm">
                      <p className="text-gray-700">{pointInfo.warehouseInfo?.nombrebodega}</p>
                    </div>
                  </div>
                  <div>
                    <label className="mb-1 block text-sm font-medium text-gray-700">
                      Dirección
                    </label>
                    <div className="block w-full rounded-md border-2 border-gray-300 bg-gray-50 px-4 py-3 shadow-sm">
                      <p className="text-gray-700">{pointInfo.warehouseInfo?.address}</p>
                    </div>
                  </div>
                  <div>
                    <label className="mb-1 block text-sm font-medium text-gray-700">
                      Email de la Tienda <span className="text-red">*</span>
                    </label>
                    <input
                      type="email"
                      name="storeEmail"
                      value={formData.storeEmail || ''}
                      onChange={handleChange}
                      className="block w-full rounded-md border-2 border-gray-300 px-4 py-3 shadow-sm focus:border-blue/50 focus:ring-2 focus:ring-blue/50"
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="p-6">
                <h2 className="mb-4 text-lg font-semibold text-gray-800">
                  Información del Solicitante
                </h2>
                <div className="space-y-4">
                  <div>
                    <label className="mb-1 block text-sm font-medium text-gray-700">
                      Nombre del Solicitante <span className="text-red">*</span>
                    </label>
                    <input
                      type="text"
                      name="applicantName"
                      value={formData.applicantName || ''}
                      onChange={handleChange}
                      className="block w-full rounded-md border-2 border-gray-300 px-4 py-3 shadow-sm focus:border-blue/50 focus:ring-2 focus:ring-blue/50"
                      required
                    />
                  </div>
                  <div>
                    <label className="mb-1 block text-sm font-medium text-gray-700">
                      Teléfono del Solicitante <span className="text-red">*</span>
                    </label>
                    <input
                      type="tel"
                      name="applicantPhone"
                      value={formData.applicantPhone || ''}
                      onChange={handleChange}
                      className="block w-full rounded-md border-2 border-gray-300 px-4 py-3 shadow-sm focus:border-blue/50 focus:ring-2 focus:ring-blue/50"
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="p-6">
                <h2 className="mb-4 text-lg font-semibold text-gray-800">
                  Información de los Bultos
                </h2>
                <div className="grid gap-4 md:grid-cols-2">
                  <div>
                    <label className="mb-1 block text-sm font-medium text-gray-700">
                      Número de Paquetes <span className="text-red">*</span>
                    </label>
                    <input
                      type="number"
                      name="packageCount"
                      value={formData.packageCount || ''}
                      onChange={handleChange}
                      className="block w-full rounded-md border-2 border-gray-300 px-4 py-3 shadow-sm focus:border-blue/50 focus:ring-2 focus:ring-blue/50"
                      min="1"
                      required
                    />
                  </div>
                  <div>
                    <label className="mb-1 block text-sm font-medium text-gray-700">
                      Peso Total (kg) <span className="text-red">*</span>
                    </label>
                    <input
                      type="number"
                      name="totalWeight"
                      value={formData.totalWeight || ''}
                      onChange={handleChange}
                      className="block w-full rounded-md border-2 border-gray-300 px-4 py-3 shadow-sm focus:border-blue/50 focus:ring-2 focus:ring-blue/50"
                      min="0"
                      step="0.1"
                      required
                    />
                  </div>
                </div>
              </div>

              <div className="p-6">
                <h2 className="mb-4 text-lg font-semibold text-gray-800">Información de Retiro</h2>
                <div className="space-y-4">
                  <div>
                    <label className="mb-1 block text-sm font-medium text-gray-700">
                      Fecha de Retiro <span className="text-red">*</span>
                    </label>
                    <input
                      type="date"
                      name="date"
                      value={formData.date || ''}
                      onChange={handleChange}
                      className="block w-full rounded-md border-2 border-gray-300 px-4 py-3 shadow-sm focus:border-blue/50 focus:ring-2 focus:ring-blue/50"
                      required
                    />
                  </div>
                  <div className="grid gap-4 md:grid-cols-2">
                    <div>
                      <label className="mb-1 block text-sm font-medium text-gray-700">
                        Hora Desde <span className="text-red">*</span>
                      </label>
                      <input
                        type="time"
                        name="timeFrom"
                        value={formData.timeFrom || ''}
                        onChange={handleChange}
                        className="block w-full rounded-md border-2 border-gray-300 px-4 py-3 shadow-sm focus:border-blue/50 focus:ring-2 focus:ring-blue/50"
                        required
                      />
                    </div>
                    <div>
                      <label className="mb-1 block text-sm font-medium text-gray-700">
                        Hora Hasta <span className="text-red">*</span>
                      </label>
                      <input
                        type="time"
                        name="timeTo"
                        value={formData.timeTo || ''}
                        onChange={handleChange}
                        className="block w-full rounded-md border-2 border-gray-300 px-4 py-3 shadow-sm focus:border-blue/50 focus:ring-2 focus:ring-blue/50"
                        required
                      />
                    </div>
                  </div>
                  <div>
                    <label className="mb-1 block text-sm font-medium text-gray-700">
                      Comentarios Adicionales
                    </label>
                    <textarea
                      name="comments"
                      value={formData.comments || ''}
                      onChange={handleChange}
                      placeholder="Ej: Local 302"
                      className="block w-full rounded-md border-2 border-gray-300 px-4 py-3 shadow-sm focus:border-blue/50 focus:ring-2 focus:ring-blue/50"
                      rows="2"
                    />
                  </div>
                </div>
              </div>

              <div className="bg-gray-50 px-6 py-4">
                <button
                  type="submit"
                  disabled={submitting}
                  className={`w-full rounded-md bg-pinflag-dark px-4 py-3 text-base font-medium
                  text-white shadow-sm transition hover:bg-pinflag-dark/80 focus:outline-none
                  focus:ring-2 focus:ring-blue/100 ${
                    submitting ? 'cursor-not-allowed opacity-70' : ''
                  }`}
                >
                  {submitting ? 'Enviando...' : 'Enviar Solicitud'}
                </button>
              </div>
            </form>
          )}
        </div>
      </div>
    </div>
  )
}

export default ReturnRequestView
