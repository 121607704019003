import React, { useContext } from 'react'
import { Link } from 'react-router-dom'

import ROUTES from '../constants/routes'
import { manifest, NAV_ICONS, replenishment, returned, truckReturn } from '../constants/icons'
import { MenuContext } from '../contexts/MenuContext'

const replenishmentOptions = [
  { route: ROUTES.INGRESS, icon: NAV_ICONS.entry, label: 'Ingreso' },
  { route: ROUTES.IDENTIFY_PACKAGE, icon: NAV_ICONS.store, label: 'Almacenar' },
  { route: ROUTES.DELIVER, icon: NAV_ICONS.deliver, label: 'Entregar' },
  { route: ROUTES.CLAIM, icon: NAV_ICONS.claim, label: 'Reclamar' },
  { route: ROUTES.PACKAGES, icon: NAV_ICONS.packages, label: 'Pedidos' }
]

const distributionOptions = [
  { route: ROUTES.REPLENISHMENT, icon: replenishment, label: 'Recepcionar' },
  { route: ROUTES.DISTRIBUTION_RETURN, icon: returned, label: 'Retorno' },
  { route: ROUTES.MANIFESTS, icon: manifest, label: 'Manifiestos' },
  { route: ROUTES.RETURN_REQUEST, icon: truckReturn, label: 'Solicitar Retiro' }
]

const BottomNavbar = () => {
  const { isDistribution, isMobile } = useContext(MenuContext)

  const replenishmentRoute = { route: ROUTES.REPLENISHMENT, icon: replenishment, label: 'Recepcionar' }

  let  options = distributionOptions

  if (!isDistribution) {
    options = isMobile ? [...replenishmentOptions, replenishmentRoute] : replenishmentOptions
  }

  return(
    <div className="fixed inset-x-0 bottom-0 z-10 block h-20 bg-white text-base text-medium-gray shadow-[0_-5px_10px_rgba(0,0,0,0.05)] xs:text-xs">
      <div className="flex h-full items-center justify-center gap-2 px-2 lg:gap-16">
        {options.map((option) => (
          <Link
            key={option.route}
            to={option.route}
            className="flex flex-col items-center gap-y-1 pt-2 pb-1 text-center hover:opacity-75"
          >
            <img src={option.icon} alt={option.label} />
            <span className="block text-xs lg:text-base">{option.label}</span>
          </Link>
        ))}
      </div>
    </div>
  )}

export default BottomNavbar
