import React from 'react'

import { useReturnRequest } from '../../components/DistributionReturn/ReturnRequest/useReturnRequest'
import ReturnRequestView from '../../components/DistributionReturn/ReturnRequest/ReturnRequestView'

const ReturnRequest = () => {
  const {
    loadingColumns,
    submitting,
    formData,
    message,
    setMessage,
    handleChange,
    submitForm,
    pointInfo
  } = useReturnRequest()

  const handleSubmit = (e) => {
    e.preventDefault()
    submitForm()
  }

  return (
    <ReturnRequestView
      loadingColumns={loadingColumns}
      submitting={submitting}
      formData={formData}
      message={message}
      setMessage={setMessage}
      handleChange={handleChange}
      handleSubmit={handleSubmit}
      pointInfo={pointInfo}
    />
  )
}

export default ReturnRequest
