import React, { useContext, useEffect, useState } from 'react'
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom'

import { AuthContext } from './contexts/AuthContext'
import './App.css'
import Interceptors from './Interceptors'
import Home from './pages/Home'
import NotFound from './pages/NotFound'
import Login from './pages/Login'
import ROUTES from './constants/routes'
import Ingress from './pages/Ingress'
import ScanProvider from './contexts/ScanContext'
import StorePackage from './pages/Store/StorePackage'
import IdentifyPackage from './pages/Store/IdentifyPackage'
import Deliver from './pages/Deliver'
import PackageDetail from './pages/Packages/PackageDetail'
import SearchPackages from './pages/Packages/SearchPackages'
import ClaimList from './pages/Claim/ClaimList'
import ClaimProvider from './contexts/ClaimContext'
import ClaimPackage from './pages/Claim/ClaimPackage'
import WeighingScan from './pages/Replenishment/WeighingScan'
import Weighing from './pages/Replenishment/Weighing'
import Return from './pages/DistributionReturn/CreateReturnPackage'
import ManifestsList from './pages/Manifests/ManifestsList'
import ManifestsDetail from './pages/Manifests/ManifestDetail'
import ManifestPackage from './pages/Manifests/ManifestPackage'
import { getPointStats } from './helpers/requests/pointInformation'
import MenuProvider from './contexts/MenuContext'
import NotificationWrapper from './contexts/NotificationContext'
import PackagePicking from './pages/PackagePicking'
import RETURN_REQUEST from './pages/DistributionReturn/ReturnRequest'

window.addEventListener('load', () => {
  const currentRoute = window.location.pathname
  if (currentRoute.includes('/reposicion/pesaje')) {
    window.location.href = '/reposicion'
  }
})

const App = () => {
  const { auth, setAuth, apiKey, setApiKey, warehouseId, setWarehouseId } = useContext(AuthContext)
  const [dataLoaded, setDataLoaded] = useState(false)

  useEffect(() => {
    const checkWarehouseData = async () => {
      if (auth) {
        if (!apiKey || !warehouseId) {
          try {
            const data = await getPointStats()
            setWarehouseId(data.warehouseInfo.warehouseId)
            setApiKey(data.apiKey)
          } catch {
            localStorage.clear()
            setAuth(false)
          }
        }
      }
      setDataLoaded(true)
    }

    checkWarehouseData()
  }, [auth, apiKey, warehouseId, setApiKey, setWarehouseId, setAuth])

  if (!dataLoaded) {
    return <div>Loading...</div>
  }

  return (
    <Router>
      <Interceptors>
        {auth ? (
          <NotificationWrapper>
            <div className="flex h-screen flex-row text-center font-lexend">
              <ScanProvider>
                <ClaimProvider>
                  <MenuProvider>
                    <Routes>
                      <Route exact path="/" element={<Home />} />
                      <Route path={ROUTES.INGRESS} element={<Ingress />} />
                      <Route path={ROUTES.IDENTIFY_PACKAGE} element={<IdentifyPackage />} />
                      <Route path={ROUTES.STORAGE} element={<StorePackage />} />
                      <Route path={ROUTES.DELIVER} element={<Deliver />} />
                      <Route path={ROUTES.CLAIM} element={<ClaimList />} />
                      <Route path={ROUTES.CLAIM_PACKAGE} element={<ClaimPackage />} />
                      <Route path={ROUTES.PACKAGES} element={<SearchPackages />} />
                      <Route path={ROUTES.PACKAGE_DETAIL} element={<PackageDetail />} />
                      <Route path={ROUTES.REPLENISHMENT} element={<WeighingScan />} />
                      <Route path={ROUTES.WEIGHING} element={<Weighing />} />
                      <Route path={ROUTES.DISTRIBUTION_RETURN} element={<Return />} />
                      <Route path={ROUTES.MANIFESTS} element={<ManifestsList />} />
                      <Route path={ROUTES.MANIFESTS_DETAIL} element={<ManifestsDetail />} />
                      <Route path={ROUTES.MANIFEST_PACKAGE} element={<ManifestPackage />} />
                      <Route path={ROUTES.PICKING} element={<PackagePicking />} />
                      <Route path={ROUTES.RETURN_REQUEST} element={<RETURN_REQUEST />} />
                      <Route path="*" element={<NotFound />} />
                    </Routes>
                  </MenuProvider>
                </ClaimProvider>
              </ScanProvider>
            </div>
          </NotificationWrapper>
        ) : (
          <Routes>
            <Route path="*" element={<Login />} />
          </Routes>
        )}
      </Interceptors>
    </Router>
  )
}

export default App
