import React, { useState } from 'react'

import { getPackagesByServiceTypeAndStatus } from '../../helpers/requests/packagesInformation'
import Modal from '../shared/Modal/Modal'
import Box from '../shared/Box/Box'
import Loader from '../shared/Loader'
import PackageList from '../shared/PackageList'
import {
  PACKAGE_BOUGHT,
  PACKAGE_IN_TRANSIT,
  PACKAGE_ON_DELIVERY,
  PACKAGE_PROCESSED
} from '../../constants/packageStatus'

const InformationBox = ({ title, number, image, capacity, status, serviceType }) => {
  const [open, setOpen] = useState(false)
  const [packagesInfo, setPackagesInfo] = useState([])
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const selectedServiceType = serviceType === 'pickup' ? 'pick-from-store,standard' : serviceType

  const closeModal = () => {
    setOpen(false)
  }

  const handleClick = async () => {
    setLoading(true)
    setError(false)
    setOpen(true)
    try {
      const response = await getPackagesByServiceTypeAndStatus({
        serviceType: selectedServiceType,
        status
      })
      setPackagesInfo(response)
    } catch {
      setError(true)
    } finally {
      setLoading(false)
    }
  }

  const modalInformation = () => {
    switch (true) {
      case loading:
        return <Loader />
      case error:
        return (
          <div className="my-8 text-red">Ha ocurrido un error. Inténtelo de nuevo más tarde.</div>
        )
      default:
        return (
          <PackageList
            packagesInfo={packagesInfo}
            statusText={
              status ===
              [PACKAGE_BOUGHT, PACKAGE_PROCESSED, PACKAGE_IN_TRANSIT, PACKAGE_ON_DELIVERY].join(',')
            }
            detail
          />
        )
    }
  }

  return (
    <div>
      <Box height="h-full">
        <div className="flex flex-col items-center justify-center p-2" role="button" tabIndex={0} onClick={handleClick}>
          <div className="flex items-center justify-center gap-4 p-2">
            <div className="text-lg font-bold sm:text-5xl xs:text-3xl">
              {capacity ? '' : number}
            </div>
            <div className="flex w-full items-center justify-center lg:w-auto">
              <img className="h-auto w-14 md:p-2" src={image} alt="box" />
            </div>
          </div>
          <div className="flex justify-start pb-4 text-left text-xs font-light text-medium-gray md:text-lg xs:text-xs">
            <div>{title}</div>
          </div>
        </div>
      </Box>
      <Modal show={open} handleClose={closeModal}>
        <div className="flex flex-col items-center justify-center">
          <img className="md:p-6" src={image} alt="box" />
          <div className="flex items-end gap-x-4 ">
            <div className="mt-auto text-3xl font-bold leading-none">{number}</div>
            <div className="mt-auto text-xl font-bold leading-none">Pedidos</div>
            <div className="mt-auto text-xs font-light leading-none text-medium-gray md:text-lg xs:text-sm">
              {title}
            </div>
          </div>
          <div className="mt-5 flex max-h-60 w-5/6 flex-col gap-y-5 overflow-y-auto">
            {modalInformation()}
          </div>
        </div>
      </Modal>
    </div>
  )
}

export default InformationBox
