import apiClient from '../../axiosConfig/apiClient'
import { SALE_URL, PACKAGES_BY_SERVICE_TYPE_AND_STATUS, PACKAGE_PERSONALIZATION } from '../../constants/api'

export const getPackageInformation = async (saleId) => {
  const response = await apiClient.get(SALE_URL(saleId))

  return response.data
}

export const getPackagesByStatus = async (status) => {
  const response = await apiClient.get(PACKAGES_BY_SERVICE_TYPE_AND_STATUS({ status }))

  return response.data
}

export const getPackageForNotifications = async () => {
  const response = await apiClient.get(PACKAGES_BY_SERVICE_TYPE_AND_STATUS({
    serviceType: 'ship-from-store,pick-from-store',
    status: 'bought'
  }))

  return response.data
}

export const getPackagesByServiceTypeAndStatus = async ({ serviceType, status }) => {
  const response = await apiClient.get(PACKAGES_BY_SERVICE_TYPE_AND_STATUS({ serviceType, status }))

  return response.data
}

export const getPackagePersonalization = async (packageId) => {
  const response = await apiClient.get(PACKAGE_PERSONALIZATION(packageId))

  return response.data
}
