import axios from 'axios'

import { MONDAY_API_URL, MONDAY_API_KEY, RETURN_REQUEST_BOARD_ID } from '../../constants/api'



const getColumnsQuery = () => `
  query {
    boards(ids: "${RETURN_REQUEST_BOARD_ID}") {
      columns {
        id
        title
        type
      }
    }
  }
`

const fetchColumnsData = async () => {
  const response = await axios.post(MONDAY_API_URL, { query: getColumnsQuery() }, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: MONDAY_API_KEY
    }
  })
  return response.data.data.boards[0].columns
}

const sendFormData = async (variables) => {
  const response = await axios.post(MONDAY_API_URL, {
    query: `
      mutation ($boardId: ID!, $itemName: String!, $columnValues: JSON!) {
        create_item (board_id: $boardId, item_name: $itemName, column_values: $columnValues) {
          id
        }
      }
    `,
    variables
  }, {
    headers: {
      'Content-Type': 'application/json',
      Authorization: MONDAY_API_KEY
    }
  })
  return response
}

export {
  fetchColumnsData,
  sendFormData
}
