import React from 'react'
import InformationBox from '../InformationBox'
import { HOME_ICONS } from '../../../constants/icons'
import {
  PACKAGE_BOUGHT,
  PACKAGE_IN_TRANSIT,
  PACKAGE_ON_DELIVERY,
  PACKAGE_PICKED_UP,
  PACKAGE_PROCESSED,
  PACKAGE_RECEIVED,
  PACKAGE_STORED
} from '../../../constants/packageStatus'
import CapacityBox from '../CapacityBox'

const getPickupStats = (pointInfo) => {
  const { standard, 'pick-from-store': pickFromStore } = pointInfo.packageStats
  return {
    storedCount: standard.storedCount + pickFromStore.storedCount,
    pendingStorageCount: standard.pendingStorageCount + pickFromStore.pendingStorageCount,
    toReceiveCount: standard.toReceiveCount + pickFromStore.toReceiveCount
  }
}

const StatsInformation = ({ pointInfo, error, serviceType }) => {
  const stats = serviceType === 'pickup'
    ? getPickupStats(pointInfo)
    : pointInfo.packageStats[serviceType]

  const capacityInfo = () => {
    if (serviceType === 'pickup') return pointInfo.warehouseInfo
    if (serviceType === 'ship-from-store') return pointInfo.warehouseInfo.shipFromStore
    return null
  }

  if (error) {
    return (
      <div className="flex h-full w-full flex-col justify-center bg-light-gray text-center">
        <div className="mt-24">
          Ocurrió un error obteniendo las estadísticas. Inténtelo de nuevo más tarde o contacte al
          soporte Pinflag.
        </div>
      </div>
    )
  }
  return (
    <div className="grid grid-cols-3 gap-4">
      <InformationBox
        title="Almacenados"
        number={stats.storedCount}
        image={HOME_ICONS.packageIcon}
        status={[PACKAGE_STORED, PACKAGE_PICKED_UP].join(',')}
        serviceType={serviceType}
      />
      <InformationBox
        title="Por Almacenar"
        number={stats.pendingStorageCount}
        image={HOME_ICONS.receivedIcon}
        status={PACKAGE_RECEIVED}
        serviceType={serviceType}
      />
      <InformationBox
        title="Por Recibir"
        number={stats.toReceiveCount}
        image={HOME_ICONS.truckIcon}
        status={[PACKAGE_BOUGHT, PACKAGE_PROCESSED, PACKAGE_IN_TRANSIT, PACKAGE_ON_DELIVERY].join(
          ','
        )}
        serviceType={serviceType}
      />
      {capacityInfo() && (
        <CapacityBox
          title="Espacios disponibles"
          number={capacityInfo().capacityPercentage}
          capacity={{
            packagesCount: capacityInfo().packagesCount,
            capacidadbultos: capacityInfo().capacidadbultos || capacityInfo().capacity
          }}
        />
      )}
    </div>
  )
}

export default StatsInformation
