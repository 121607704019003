import React, { useState } from 'react'

import useSession from '../../hooks/useSession'
import { arrowdown, HOME_ICONS } from '../../constants/icons'

const UserMenu = ({ userImage, userName }) => {
  const [isOpen, setIsOpen] = useState(false)
  const { logout } = useSession()

  return (
    <div>
      <div
        className="flex w-[90%] cursor-pointer items-center gap-1 md:gap-2 lg:w-full"
        role="button"
        tabIndex={0}
        onClick={() => setIsOpen(!isOpen)}
      >
        <img className="w-16 rounded-full" src={userImage} alt="profile" />
        <p className="break-words font-bold text-white">{userName}</p>
        <img
          src={arrowdown}
          className={`h-6 w-6 invert transition-transform ${isOpen ? 'rotate-180' : ''}`}
          alt="Ver más"
        />
      </div>

      {isOpen && (
        <div className="absolute z-10 mt-2 w-48 rounded-md bg-white shadow-lg ring-1 ring-black/5">
          <div className="py-1">
            <button
              type="button"
              className="flex w-full items-center gap-2 px-4 py-2 text-sm text-gray-700 hover:bg-gray-100"
              onClick={logout}
            >
              <img
                src={HOME_ICONS.logoutIcon}
                className="h-6 w-6 opacity-60 brightness-0 grayscale"
                alt="logout"
                />
              Cerrar Sesión
            </button>
          </div>
        </div>
      )}
    </div>
  )
}

export default UserMenu
